import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";


// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Roles
import RolesList from "pages/Roles/RolesList";
import AddRole from "pages/Roles/AddRole";
import EditRole from "pages/Roles/EditRole";

// Users
import UsersList from "pages/Users/UsersList";
import AddUser from "pages/Users/AddUser";
import EditUser from "pages/Users/EditUser";
import RemoveUser from "pages/Users/RemoveUser";

// Countries
import CountryList from "pages/Countries/CountryList";
import AddCountry from "pages/Countries/AddCountry";
import EditCountry from "pages/Countries/EditCountry";
import RemoveCountry from "pages/Countries/RemoveCountry";

//Tickets
import TicketsList from "pages/Tickets/TicketsList";
import TicketDetails from "pages/Tickets/TicketDetails";

// Application Setup
import ApplicationSetup from "pages/Application/setup";

// Terms
import TermsList from "pages/Terms/TermsList";
import AddTerm from "pages/Terms/AddTerm";
import UpdateTerm from "pages/Terms/UpdateTerm";

// FAQs
import FaqList from "pages/FAQs/FaqList";
import AddFaq from "pages/FAQs/AddFaq";
import UpdateFaq from "pages/FAQs/UpdateFaq";

//Categories
import CategoryList from "pages/Categories/CategoryList";
import AddCategory from "pages/Categories/AddCategory";
import EditCategory from "pages/Categories/EditCategory";
import RemoveCategory from "pages/Categories/RemoveCategory";

//Banners
import BannersList from "pages/Banners/BannersList";
import AddBanner from "pages/Banners/AddBanner";
import EditBanner from "pages/Banners/EditBanner";

//Offers
import OffersList from "pages/Offers/OffersList";
import AddOffer from "pages/Offers/AddOffer";
import EditOffer from "pages/Offers/EditOffer";

//Vehicles
import VehicleList from "pages/Vehicles/VehicleList";
import AddVehicle from "pages/Vehicles/AddVehicle";
import EditVehicle from "pages/Vehicles/EditVehicle";

//Vehicle Categories
import VehicleCategoriesList from "pages/Vehicles/VehicleCategories/VehiceCategoriesList";
import AddVehicleCategory from "pages/Vehicles/VehicleCategories/AddVehicleCategory";
import EditVehicleCategory from "pages/Vehicles/VehicleCategories/EditVehicleCategory";


//Screens
import ScreensList from "pages/Screens/ScreensList";
import AddScreen from "pages/Screens/AddScreen";
import EditScreen from "pages/Screens/EditScreen";
import SectionsList from "pages/Screens/Sections/SectionsList";
import AddSection from "pages/Screens/Sections/AddSection";
import EditSection from "pages/Screens/Sections/EditSection";

//Drivers
import DriversList from "pages/Drivers/DriversList";
import AddDriver from "pages/Drivers/AddDriver";
import EditDriver from "pages/Drivers/EditDriver";

//Items
import ItemsList from "pages/Items/ItemsList";
import AddItem from "pages/Items/AddItem";
import EditItem from "pages/Items/EditItem";

//Shipment Types
import ShipmentTypeList from "pages/ShipmentTypes/ShipmentTypeList";
import AddShipmentType from "pages/ShipmentTypes/AddShipmentType";
import EditShipmentType from "pages/ShipmentTypes/EditShipmentType";



const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  //profile
  { path: "/profile", component: <UserProfile /> },

  //Roles
  { path: "/roles", component: <RolesList /> },
  { path: "/roles/add/", component: <AddRole /> },
  { path: "/roles/edit/:id", component: <EditRole /> },

  //users
  { path: "/users", component: <UsersList /> },
  { path: "/users/add", component: <AddUser /> },
  { path: "/users/edit/:id", component: <EditUser /> },
  { path: "/users/remove/:id", component: <RemoveUser /> },

  //countries
  { path: "/countries", component: <CountryList /> },
  { path: "/countries/add", component: <AddCountry /> },
  { path: "/countries/edit/:id", component: <EditCountry /> },
  { path: "/countries/remove/:id", component: <RemoveCountry /> },

  //countries
  { path: "/application/setup", component: <ApplicationSetup /> },

  //Tickets
  { path: "/tickets", component: <TicketsList /> },
  { path: "/tickets/details/:id", component: <TicketDetails /> },

  //Terms
  { path: "/terms", component: <TermsList /> },
  { path: "/terms/add/", component: <AddTerm /> },
  { path: "/terms/update/:id", component: <UpdateTerm /> },

  //FAQs
  { path: "/faqs", component: <FaqList /> },
  { path: "/faqs/add/", component: <AddFaq /> },
  { path: "/faqs/update/:id", component: <UpdateFaq /> },

  //Categories
  { path: "/categories", component: <CategoryList /> },
  { path: "/categories/add", component: <AddCategory /> },
  { path: "/categories/edit/:id", component: <EditCategory /> },
  { path: "/categories/remove/:id", component: <RemoveCategory /> },

  //Banners
  { path: "/banners", component: <BannersList /> },
  { path: "/banners/add", component: <AddBanner /> },
  { path: "/banners/edit/:id", component: <EditBanner /> },
  { path: "/banners/remove/:id", component: <RemoveCategory /> },

  //Offers
  { path: "/offers", component: <OffersList /> },
  { path: "/offers/add", component: <AddOffer /> },
  { path: "/offers/edit/:id", component: <EditOffer /> },
  { path: "/offers/remove/:id", component: <RemoveCategory /> },

  //Vehicles
  { path: "/vehicles", component: <VehicleList /> },
  { path: "/vehicles/add", component: <AddVehicle /> },
  { path: "/vehicles/edit/:id", component: <EditVehicle /> },
  { path: "/vehicles/remove/:id", component: <RemoveCategory /> },

  //Vehicle Categories
  { path: "/vehicle-categories", component: <VehicleCategoriesList /> },
  { path: "/vehicle-categories/add", component: <AddVehicleCategory /> },
  { path: "/vehicle-categories/edit/:id", component: <EditVehicleCategory /> },
  { path: "/vehicle-categories/remove/:id", component: <RemoveCategory /> },

  //Screens
  { path: "/screens", component: <ScreensList /> },
  { path: "/screens/add", component: <AddScreen /> },
  { path: "/screens/edit/:id", component: <EditScreen /> },
  { path: "/sections", component: <SectionsList /> },
  { path: "/sections/add", component: <AddSection /> },
  { path: "/sections/edit/:id", component: <EditSection /> },

  //Drivers
  { path: "/drivers", component: <DriversList /> },
  { path: "/drivers/add", component: <AddDriver /> },
  { path: "/drivers/edit/:id", component: <EditDriver /> },

  //Items
  { path: "/items", component: <ItemsList /> },
  { path: "/items/add", component: <AddItem /> },
  { path: "/items/edit/:id", component: <EditItem /> },
  { path: "/items/remove/:id", component: <RemoveCategory /> },

  //Shipment Types
  { path: "/shipment-types", component: <ShipmentTypeList /> },
  { path: "/shipment-types/add", component: <AddShipmentType /> },
  { path: "/shipment-types/edit/:id", component: <EditShipmentType /> },
  { path: "/shipment-types/remove/:id", component: <RemoveCategory /> },



  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
